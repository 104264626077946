import { default as abuseReportskbthr2rcaTMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_937rvGqCFVeDMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexXF7cR0mZrWMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/index.vue?macro=true";
import { default as moderation19aQTXzf5BMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/moderation.vue?macro=true";
import { default as pendingBansZgy2ObGGqcMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/pendingBans.vue?macro=true";
import { default as profilesYZyb1t0uJYMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/profiles.vue?macro=true";
import { default as expenses3qSyGqnmRsMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexq53RDow8IUMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewWncndmHElOMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingp1ktIF9rSFMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingmB37ipQq3yMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/translations/missing.vue?macro=true";
import { default as usersNcweJtl3RvMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/admin/users.vue?macro=true";
import { default as apiHf7XRcv02aMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/api.vue?macro=true";
import { default as _91slug_93XM60sUUCOKMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/blog/[slug].vue?macro=true";
import { default as indexsv7sBWrmFUMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93qdlTiHamQOMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93Wa655pnCDgMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as admingwzR39yyOyMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/census/admin.vue?macro=true";
import { default as indexzptLmz0fP2Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/census/index.vue?macro=true";
import { default as contactl2zDtYLytZMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/contact.vue?macro=true";
import { default as designPNpYSVmtusMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/design.vue?macro=true";
import { default as englishOmwreOMeyLMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/english.vue?macro=true";
import { default as faqjl4gk045qtMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/faq.vue?macro=true";
import { default as inclusiveSxPrFAYKQqMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/inclusive.vue?macro=true";
import { default as index9OvTRu8DSVMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/index.vue?macro=true";
import { default as licenseItdbu2nuBwMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/license.vue?macro=true";
import { default as academicTxcAAXWnWmMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/links/academic.vue?macro=true";
import { default as indexpFVRUFlBf1Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/links/index.vue?macro=true";
import { default as mediadm1RttqCfnMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/links/media.vue?macro=true";
import { default as translinguisticsB6pmrvTE45Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/links/translinguistics.vue?macro=true";
import { default as zinesWbIh0SLFoMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/links/zine.vue?macro=true";
import { default as namesyrTfGkAsowMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/names.vue?macro=true";
import { default as indexWSAEE6KL6MMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/nouns/index.vue?macro=true";
import { default as templates40FCsiwcPhMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/nouns/templates.vue?macro=true";
import { default as people9H3qasee2rMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/people.vue?macro=true";
import { default as privacyXGso5v5CNkMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/privacy.vue?macro=true";
import { default as _91username_93wn6WbJuxanMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93E293lpz9u7Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/profile/card-[username].vue?macro=true";
import { default as editorhDtrQV9FwgMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/profile/editor.vue?macro=true";
import { default as anyZfQXJ9kEG4Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/any.vue?macro=true";
import { default as ask4JxXrMiKDrMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/ask.vue?macro=true";
import { default as avoidingwdf0rwSY0IMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/avoiding.vue?macro=true";
import { default as index66cqgoJ1ezMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/index.vue?macro=true";
import { default as mirrorFC50BcBHJPMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/mirror.vue?macro=true";
import { default as pronounOzXaKq6XX0Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesra64wGfDT5Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/sources.vue?macro=true";
import { default as teamNrOhkJ9jm7Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/team.vue?macro=true";
import { default as terminologyd4Zf1XtBPRMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/terminology.vue?macro=true";
import { default as termstdzCT73VzlMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/terms.vue?macro=true";
import { default as userb6kCPmKow5Meta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/user.vue?macro=true";
import { default as workshopswRo72OsQ9dMeta } from "/home/admin/www/pronombr.es/release/20241201122159/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportskbthr2rcaTMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansZgy2ObGGqcMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiHf7XRcv02aMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93XM60sUUCOKMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexsv7sBWrmFUMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93qdlTiHamQOMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93Wa655pnCDgMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: admingwzR39yyOyMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexzptLmz0fP2Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactl2zDtYLytZMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishOmwreOMeyLMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqjl4gk045qtMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveSxPrFAYKQqMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicTxcAAXWnWmMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexpFVRUFlBf1Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediadm1RttqCfnMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsB6pmrvTE45Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinesWbIh0SLFoMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesyrTfGkAsowMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexWSAEE6KL6MMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates40FCsiwcPhMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people9H3qasee2rMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyXGso5v5CNkMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93wn6WbJuxanMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93E293lpz9u7Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorhDtrQV9FwgMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyZfQXJ9kEG4Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: ask4JxXrMiKDrMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingwdf0rwSY0IMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index66cqgoJ1ezMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorFC50BcBHJPMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounOzXaKq6XX0Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesra64wGfDT5Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamNrOhkJ9jm7Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyd4Zf1XtBPRMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termstdzCT73VzlMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userb6kCPmKow5Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopswRo72OsQ9dMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241201122159/pages/workshops.vue")
  }
]